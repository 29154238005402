import { render, staticRenderFns } from "./withdrawalRecord.vue?vue&type=template&id=f5b6782e&scoped=true&"
import script from "./withdrawalRecord.vue?vue&type=script&lang=js&"
export * from "./withdrawalRecord.vue?vue&type=script&lang=js&"
import style0 from "./withdrawalRecord.vue?vue&type=style&index=0&id=f5b6782e&scoped=scoped&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5b6782e",
  null
  
)

export default component.exports